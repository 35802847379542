
import { defineComponent, nextTick, reactive, ref, computed, compile, watch} from 'vue'
import httpRequest from '@/service'
import { useRoute, useRouter } from 'vue-router'
import BreadCrumb from '@/components/breadCrumb/index.vue'
import SearchInput from '@/views/DetailPage/cpns/searchInput.vue'
import RightPane from '@/views/DetailPage/cpns/rightPane.vue'
import Rules from '@/views/DetailPage/components/MainView/Rules.vue'
import FoldPanel from '@/views/DetailPage/components/MainView/FoldPanel.vue'
import TagPanel from '@/views/DetailPage/components/MainView/TagPanel.vue'
import { ElMessage } from 'element-plus/lib/components'
import { IComObj } from '@/global/types'
import { debounce } from 'throttle-debounce'
import { theme } from '@/theme/config/theme.config'
import { Position } from '@element-plus/icons-vue'
import PopoverMenu from '@/components/PopoverMenu/index.vue'
import usePopoverMenu from '@/utils/usePopoverMenu'
export default defineComponent({
  name: 'DetailPage',
  components: {
    Position,
    Rules,
    SearchInput,
    BreadCrumb,
    RightPane,
    FoldPanel,
    TagPanel,
    PopoverMenu
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    // 划词菜单
    const { popShow, popLeft, popTop, showPop,handleCopy,handleSearch } = usePopoverMenu((value)=>{
      doParamSearch('fulltext',value,value)
    })
    const doParamSearch = (paramName: any, code: any, name: any) => {
      const routeData = router.resolve({ path: '/SearchFyList', query: { paramName, code, name } })
      window.open(routeData.href, '_blank')
    }

    // 详情数据
    const detailPageList = ref<IComObj>({})
    const currentID = ref('')
    const loading = ref(true)
    const detailData: any = ref({
      content: ''
    })
    const initDetail = () => {
      httpRequest
        .post({
          url: 'fyCaseLibraryDetail',
          params: { id: route.query.id }
        })
        .then((res) => {
          currentID.value = route.query.id as string
          detailData.value = res.data
          if(detailData.value.caseTitle){
            const title = document.getElementsByTagName('title')[0]
            title.innerHTML = detailData.value.caseTitle
          }
        }).finally(() => {
          loading.value = false
        })
    }
    initDetail()
    function matchLink(arr){
      arr.forEach(item=>{
        detailData.value.content = detailData.value.content.replaceAll('《'+item.name+'》','<span class="relate-link" data-id="'+item.id+'">'+'《'+item.name+'》'+'</span>')
      })
    }
    const dynamicContent = computed(() => (content: string) => {
      let dcontent = content.replaceAll(/(<span class="relate-link" data-id="(.+?)">.*?<\/span>)/g, `<el-popover
        popper-class="hover-pop"
        :width="800"
        :show-arrow="false"
        placement="top"
        trigger="hover"
        @show="lawSummary.loadData('$2')">
        <template #reference>
          <span v-html='\`$1\`' @click="lawSummary.jump('$2')"></span>
        </template>
        <div
          class="hover-law-summary"
          v-loading="lawSummary.loading()"
          element-loading-background="rgba(0, 0, 0, 0)">
          <div class="law-summary-title" v-if="!lawSummary.loading()">
        <h3 @click="lawSummary.jump('$2')">
          {{ lawSummary.data().lawName || '法律标题' }}
        </h3>
          </div>
          <hr v-if="!lawSummary.loading()" />
          <div class="law-summary-properties" v-if="!lawSummary.loading()">
        <el-row>
          <el-col :span="14">
        <span class="law-summary-properties-label">发布机关</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().publishOrgName }}</span>
          </el-col>
          <el-col :span="10">
        <span class="law-summary-properties-label">时效性</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().timelinessName }}</span>
          </el-col>
          <el-col :span="14">
        <span class="law-summary-properties-label">发文字号</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().lawNumber }}</span>
          </el-col>
          <el-col :span="10">
        <span class="law-summary-properties-label">效力级别</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().effectLevelName }}</span>
          </el-col>
          <el-col :span="14">
        <span class="law-summary-properties-label">发布日期</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().publishDate }}</span>
          </el-col>
          <el-col :span="10">
        <span class="law-summary-properties-label">实施日期</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().implDate }}</span>
          </el-col>
        </el-row>
          </div>
        </div>
      </el-popover>`)
      return compile(dcontent)
    })
    const cacheObj: { [index: string]: any } = {}
    const lawSummary = reactive({
      currentId: '',
      data() {
        return this.cache['law_' + this.currentId] || {
          lawVersionId: undefined,
          lawName: undefined,
          publishOrgName: undefined,
          timelinessName: undefined,
          lawNumber: undefined,
          effectLevelName: undefined,
          publishDate: undefined,
          implDate: undefined
        }
      },
      cache: cacheObj,
      loading() {
        return !this.cache['law_' + this.currentId]
      },
      loadData(lawVersionId: string) {
        if (!lawVersionId) {
          return
        }
        this.currentId = lawVersionId
        if (this.cache['law_' + lawVersionId]) {
          return
        }
        httpRequest
          .get({
            url: 'lawSummary',
            params: {
              lawVersionId: lawVersionId
            }
          })
          .then((res: any) => {
            this.cache['law_' + lawVersionId] = res.data
          })
          .catch((err) => {
            ElMessage({
              message: '法律摘要加载失败',
              type: 'error'
            })
          })
      },
      jump(lawVersionId: string) {
        const { href } = router.resolve({
          name: 'DetailPage',
          query: {
            id: lawVersionId
          }
        })
        window.open(href, '_blank')
      }
    })
    // 字体大小切换
    const fontSizeRef = ref()
    const fontSizeState = reactive({
      curSize: 20, // 16-24
      sizeChange: debounce(500, (type: string) => {
        if (type === 'smaller' && fontSizeState.curSize < 18) {
          ElMessage({
            message: '已经缩小到最小',
            type: 'warning'
          })
          return
        }
        if (type === 'bigger' && fontSizeState.curSize > 22) {
          ElMessage({
            message: '已经放大到最大',
            type: 'warning'
          })
          return
        }
        fontSizeState.curSize =
          type === 'smaller'
            ? fontSizeState.curSize - 2
            : fontSizeState.curSize + 2
        fontSizeRef.value.style.fontSize = String(fontSizeState.curSize + 'px')
      }) as Function
    })

    // 背景颜色切换
    const skinState = reactive<IComObj>({
      data: theme,
      colorData: [],
      change: (item: IComObj) => {
        for (const [key, val] of Object.entries(item)) {
          document.documentElement.style.setProperty(
            '--' + `${key}`,
            String(val)
          )
        }
      },
      getData: () => {
        theme.forEach((i: IComObj) => {
          skinState.colorData.push(i.BackColor)
        })
      }
    })
    skinState.getData()
    // 文内检索
    const textSearchData = ref('')
    const searchValue = ref<string[]>([])
    const matchCount = ref(0)
    watch([detailData, searchValue], (vals)=>{
      matchCount.value = 0
      if (!vals[0].content){
        return textSearchData.value = ''
      }
      if (vals[1] && vals[1].length) {
        const searchText = vals[1].join(' ')
        textSearchData.value = vals[0].content.replaceAll(new RegExp('(' + searchText + ')(?!<)', 'g'), () => `<em class="search-em" id="#em${matchCount.value++}">${searchText}</em>`)
      }else{
        textSearchData.value = vals[0].content
      }
    },{ immediate: true, deep: true })
    const matchKey = ref(0)
    const matchIndex = ref(0)
    const changeSearch = (strArr: string[]) => {
      searchValue.value = strArr
      nextTick(() => {
        matchChange(0)
      })
    }
    const matchChange = (ind: number) => {
      matchIndex.value = ind

      let elements = document.querySelectorAll('.search-em')
      elements.forEach(element => {
        element.classList.remove('focus-em')
      })
      const element = <HTMLElement>document.querySelectorAll('.search-em')[ind]
      if (element) {
        element.classList.add('focus-em')
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      }
    }

    return {
      loading,
      matchCount,
      matchIndex,
      matchKey,
      matchChange,
      textSearchData,
      detailPageList,
      searchValue,
      changeSearch,
      fontSizeRef,
      fontSizeState,
      skinState,
      currentID,
      detailData,
      matchLink,
      dynamicContent,
      lawSummary,
      doParamSearch,
      popShow,
      popLeft,
      popTop,
      showPop,
      handleCopy,
      handleSearch,
      REQUIRE_LOGIN:process.env.VUE_APP_REQUIRE_LOGIN
    }
  }
})
